import React, {useEffect, useState} from "react";
import { Navigate} from 'react-router-dom'
import {useEnd, useModal, useProfile} from "../context";
import CodesWidget from "../components/codes/codes-widget";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import {pagination} from "../helpers/pagination";
import InputMask from "react-input-mask";
import {goToAnchor} from "../helpers/go-to-anchor";
import Info from "../components/modals/Info";

const perPage = 6;
const prizesMap = {
    1: require("../assets/img/prizes/1.png"),
    2: require("../assets/img/prizes/2.png"),
    3: require("../assets/img/prizes/3.png"),
    4: require("../assets/img/prizes/4.png"),
    5: require("../assets/img/prizes/5.png"),
    6: require("../assets/img/prizes/6.png"),
    7: require("../assets/img/prizes/7.png"),
    8: require("../assets/img/prizes/8.png"),
    9: require("../assets/img/prizes/9.png"),
}

function Cabinet() {
    const [loading, setLoading] = useState(false);
    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const [prizeDownloading, setPrizeDownloading] = useState(null);
    const [codes, setCodes] = useState([]);
    const [codesPaging, setCodesPaging] = useState({
        total: 1,
        current_page: 1
    });
    const [prizesLoading, setPrizesLoading] = useState(true);
    const [prizes, setPrizes] = useState([]);

    const { show, hide} = useModal();

    useEffectOnce(()=>{
        if(localStorage.getItem('auth_key')){
            getCodes();
            getPrizes();
        }
    });

    useEffect(() => {
        if(window.location.hash){
            setTimeout(function (){
                goToAnchor(window.location.hash.substring(1));
                window.history.pushState({param: 'Value'}, '', '/cabinet');
            }, 500);
        }
    },[]);

    const getCodes = async(page = 1) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('/receipts/api/profile-receipts?per-page=' + perPage + '&page=' + page);
            if(response.data){
                setCodes(response.data.items);
                setCodesPaging({
                    total: response.data._meta.pageCount,
                    current_page: response.data._meta.currentPage
                });
                setLoading(false);
            }
        } catch (error) {
            if(error.response.data){
                setLoading(false);
            }
        }
    }

    const getPrizes = async(page = 1) => {
        try {
            const response = await axiosInstance.get('/prizes/api/profile-prizes?per-page=100&page=' + page);
            if(response.data){
                setPrizes(response.data.items);
                setPrizesLoading(false);
            }
        } catch (error) {
            console.log(error);
            setPrizesLoading(false);
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(codesPaging.current_page), codesPaging.total);
        if(codesPaging.total > 1){
            return(
                <div className="pager">
                    <div onClick={()=>{
                        if(codesPaging.current_page !== 1){
                            getCodes(parseInt(codesPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev ' + ((codesPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2L3 11L12 20" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="pager__list">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    getCodes(parseInt(page))
                                }} className={'pager__item ' + ((page == codesPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(codesPaging.total !== codesPaging.current_page){
                            getCodes(parseInt(codesPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next ' + ((codesPaging.total === codesPaging.current_page) ? 'disabled' : '')}>
                        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L11 11L2 20" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    const download = (filename, content) => {
        let element = document.createElement("a");
        element.setAttribute("href", content);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const downloadPrize = async (id) => {
        setPrizeDownloading(id);

        try {
            const response = await axiosInstance.get('/prizes/api/download/' + id);
            if(response.data){
                console.log(response.data);
                download(response.data.split('/').pop(), response.data);
            }
            setPrizeDownloading(null);
        } catch (error) {
            setPrizeDownloading(null);
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/" replace />)
    }

    return (
        <div className="content">
            <div className="sc__lk">
                <div className="bg__texture"/>
                <div className="sc__lk-bg-top">
                    <svg className="desk-only" viewBox="0 0 1600 302" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M0 252C0 252 336.022 302 800 302C1263.98 302 1600 252 1600 252V0H0V252Z" fill="#FF0000"/>
                    </svg>
                    <svg className="mb-only" viewBox="0 0 360 146" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M0 126.795C0 126.795 75.605 146 180 146C284.395 146 360 126.795 360 126.795V0H0V126.795Z" fill="#FF0000"/>
                    </svg>

                </div>
                <div className="container">
                    <div className="sc__header">
                        <div className="sc__title">
                            Личный кабинет
                        </div>
                        <div className="sc__header-sep">
                            <svg>
                                <use href="#title-sep"></use>
                            </svg>
                        </div>
                    </div>

                    {profile.isLoading && (
                        <div className="loader--inline fadeIn animated">
                            <img src={require("../assets/img/loader-wh.svg").default}/>
                        </div>
                    )}

                    {!profile.isLoading && (
                        <>
                            <div className="lk__box">
                                <div className="lk__box-profile">
                                    <div className="lk__box-card lk__box-card--profile">
                                        <div className="lk__box-info">
                                            <div className="lk__box-info-it d-flex">
                                                <i className="icon__lk-user"/>
                                                <div className="lk__box-info-main">
                                                    Привет, {profile.data.first_name}
                                                </div>
                                            </div>
                                            <div className="lk__box-info-text">
                                                {profile.data.email}
                                            </div>
                                            <div className="lk__box-info-text">
                                                <InputMask
                                                    autoComplete="nope"
                                                    type="text"
                                                    mask="+7 999-999-99-99"
                                                    value={profile.data.phone}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lk__box-card lk__box-card--codes">
                                        <div className="lk__info-codes-ct">
                                            <div className="lk__info-codes-label">
                                                Всего загружено чеков: {profile.data.receipts_count}
                                            </div>
                                            <div className="lk__info-codes-actions">
                                                <CodesWidget module="lk" classesList="btn btn--full" title="Загрузить чек" icon={true}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lk__box">
                                <div id="codes" className="anchor"/>
                                <div className="lk__box-title">
                                    Загруженные чеки
                                </div>
                                {codes.length > 0 && (
                                    <div className={'tb tb--lk' + (loading ? ' tb--loading' : '')}>
                                        <div className="tb__thead desk-only">
                                            <div className="tb__row">
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Чек
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Дата
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Статус чека
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        Приз
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tb__tbody">
                                            {codes.map((code, i) => (
                                                <div className="tb__row">
                                                    <div className="tb__col" data-title="Чек">
                                                        <div className="tb__td">
                                                            {/*{code.photos && code.photos.length > 0 && (
                                                                    <a onClick={()=>{
                                                                        show(<ImgCheck
                                                                            close={hide}
                                                                            img={code.photos}
                                                                            check_fp={code.qr ? code.qr.fp : null}
                                                                            check_fn={code.qr ? code.qr.fn : null}
                                                                            check_fd={code.qr ? code.qr.fd : null}
                                                                        />, "modal--form modal--img");
                                                                    }}  className="tb__img">
                                                                        <img src={code.photos[0]}/>
                                                                    </a>
                                                                )}*/}
                                                            <span>{code.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title="Дата">
                                                        <div className="tb__td">
                                                            {code.created_at}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title="Статус">
                                                        <div className="tb__td tb__td--status">
                                                            <span>{code.status_name}</span>

                                                            {(code.status === 'bad' || code.status === 'reject' || code.status === 'fail' || code.reject_reason) && (
                                                                <div className="hint">
                                                                    <div className="hint__toggle">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="12" cy="12" r="12" fill="#FED731"/>
                                                                            <path d="M12.6596 10.2792C12.8632 9.99412 12.965 9.6683 12.965 9.30175C12.965 8.93521 12.8276 8.62466 12.5527 8.37012C12.2778 8.11557 11.9367 7.9883 11.5294 7.9883C10.7759 7.9883 10.1498 8.31412 9.65085 8.96575L8.6123 7.78975C8.8974 7.33157 9.30467 6.95993 9.83412 6.67484C10.3738 6.37957 10.9745 6.23193 11.6363 6.23193C12.6239 6.23193 13.4029 6.49157 13.973 7.01084C14.5534 7.51993 14.8436 8.1563 14.8436 8.91993C14.8436 9.3883 14.7672 9.81084 14.6145 10.1876C14.4618 10.5643 14.2734 10.8647 14.0494 11.0887C13.8254 11.3025 13.5861 11.5214 13.3316 11.7454C13.0872 11.9592 12.8581 12.1527 12.6443 12.3258C12.4407 12.4887 12.2829 12.6974 12.1709 12.9519C12.0589 13.1963 12.0283 13.4661 12.0792 13.7614L10.4756 13.7767C10.3941 13.2778 10.4196 12.8501 10.5519 12.4938C10.6945 12.1272 10.8981 11.8319 11.1629 11.6079C11.4276 11.3839 11.6923 11.1752 11.957 10.9818C12.2319 10.7883 12.4661 10.5541 12.6596 10.2792ZM10.1396 15.9454C10.1396 15.6603 10.2516 15.4007 10.4756 15.1665C10.6996 14.9323 10.9898 14.8152 11.3461 14.8152C11.6719 14.8152 11.9418 14.9323 12.1556 15.1665C12.3694 15.4007 12.4763 15.6603 12.4763 15.9454C12.4763 16.2712 12.3694 16.5563 12.1556 16.8007C11.9418 17.045 11.6719 17.1672 11.3461 17.1672C10.9898 17.1672 10.6996 17.045 10.4756 16.8007C10.2516 16.5563 10.1396 16.2712 10.1396 15.9454Z" fill="black"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="hint__popover">
                                                                        {code.reject_reason}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title="Приз">
                                                        <div className="tb__td tb__td--status">
                                                            {code.reject_reason && code.prizes.length === 0 && (
                                                                <>
                                                                    <i className="icon__no-prize"/>
                                                                    <span>Не выиграл</span>
                                                                </>
                                                            )}

                                                            {code.status === 'checking' && code.prizes.length === 0 && (
                                                                <>
                                                                    <i className="icon__prize"/>
                                                                    <span>Ожидает розыгрыш</span>
                                                                </>
                                                            )}

                                                            {code.status === 'approved' && code.prizes && code.prizes.length > 0 && (
                                                                <>
                                                                    <div className="tb__td-img">
                                                                        <img src={require('../assets/img/codes-prizes/' + code.prizes[0].type + '.png')}/>
                                                                    </div>
                                                                    <span>{JSON.parse(code.prizes[0].data).name}</span>
                                                                    {code.prizes[0].prize_link && (
                                                                        <div className="tb__td-btn">
                                                                            <a href={code.prizes[0].prize_link} target="_blank" className="btn btn--small d-flex">
                                                                                <span>Скачать</span>
                                                                                <i className="icon__download">
                                                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="13" cy="13" r="13" transform="rotate(90 13 13)" fill="black"/>
                                                                                        <path d="M12.4697 17.8637C12.7626 18.1566 13.2374 18.1566 13.5303 17.8637L18.3033 13.0907C18.5962 12.7978 18.5962 12.3229 18.3033 12.03C18.0104 11.7372 17.5355 11.7372 17.2426 12.03L13 16.2727L8.75736 12.03C8.46447 11.7372 7.98959 11.7372 7.6967 12.03C7.40381 12.3229 7.40381 12.7978 7.6967 13.0907L12.4697 17.8637ZM12.25 6.93335L12.25 17.3333L13.75 17.3333L13.75 6.93335L12.25 6.93335Z" fill="white"/>
                                                                                    </svg>
                                                                                </i>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {codes.length === 0 && (
                                    <div className="tb__empty d-flex align-items-center justify-content-center">
                                        Пока нет загруженных чеков
                                    </div>
                                )}

                                {codesPaging.total > 1 && (
                                    renderPager()
                                )}
                            </div>
                            <div className="lk__box">
                                <div id="prizes" className="anchor"/>
                                <div className="lk__box-title">
                                    Мои призы
                                </div>
                                {!prizesLoading && prizes.length > 0 && (
                                    <div className="prizes__grid">
                                        {prizes.map((prize, i) => (
                                            <div key={'prize-' + i} className={'prizes__card' + (prize.type === 8 ? ' prizes__card--main' : '') + (prize.type >= 7 ? ' prizes__card--no-action' : '')}>
                                                <div className="prizes__card-img">
                                                    <img src={prizesMap[prize.type]}/>
                                                </div>
                                                <div className="prizes__card-header">
                                                    <div className="prizes__card-title">
                                                        {prize.code_name}
                                                    </div>
                                                    {prize.category === 'guaranteed' && (
                                                        <div className="prizes__card-subtitle">
                                                            Гарантированный приз
                                                        </div>
                                                    )}
                                                </div>
                                                {prize.type < 7 && (
                                                    <div className={'prizes__card-action' + (!prize.prize_link ? ' disabled' : '') + (prizeDownloading === prize.prize_link ? ' loading' : '')}>
                                                        <a href={prize.prize_link} target="_blank" download className="btn btn--small d-flex">
                                                            <span>Скачать</span>
                                                            <i className="icon__download">
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="13" cy="13" r="13" transform="rotate(90 13 13)" fill="black"/>
                                                                    <path d="M12.4697 17.8637C12.7626 18.1566 13.2374 18.1566 13.5303 17.8637L18.3033 13.0907C18.5962 12.7978 18.5962 12.3229 18.3033 12.03C18.0104 11.7372 17.5355 11.7372 17.2426 12.03L13 16.2727L8.75736 12.03C8.46447 11.7372 7.98959 11.7372 7.6967 12.03C7.40381 12.3229 7.40381 12.7978 7.6967 13.0907L12.4697 17.8637ZM12.25 6.93335L12.25 17.3333L13.75 17.3333L13.75 6.93335L12.25 6.93335Z" fill="white"/>
                                                                </svg>
                                                            </i>
                                                        </a>
                                                    </div>
                                                )}

                                                {prize.type >= 7 && (
                                                    <div className="prizes__card-text">
                                                        Ожидайте запрос <br className="desk-only"/>
                                                        документов с почты <br/>
                                                        <a href="mailto:info@delicados-promo.ru">info@delicados-promo.ru</a>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {!prizesLoading && prizes.length === 0 && (
                                    <div className="tb__empty d-flex align-items-center justify-content-center">
                                        Пока нет выигранных призов
                                    </div>
                                )}

                                {prizesLoading && (
                                    <div className="loader--inline fadeIn animated">
                                        <img src={require("../assets/img/loader-wh.svg").default}/>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="hidden">
                <div id="close-check-modal" onClick={()=>{
                    document.querySelectorAll('.popup__close')[0].click();
                    goToAnchor('codes');
                }}></div>
                <div id="update-codes" onClick={()=>{
                    getCodes(1);
                }}></div>
                <div id="link-prizes" onClick={()=>{
                    goToAnchor('prizes');
                }}></div>
                <div id="inc-codes" onClick={()=>{
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            receipts_count: (profile.data.receipts_count + 1)
                        }
                    });
                }}></div>
            </div>
        </div>
    );
}

export default Cabinet;
