import React, {useEffect, useLayoutEffect, useState} from "react";
import parse from "html-react-parser";
import {useEnd, useModal, useProfile} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import {yupResolver} from "@hookform/resolvers/yup";
import {CustomScroll} from 'react-custom-scroll';
import Reg from "../components/modals/Reg";
import Feedback from "../components/modals/Feedback";
import {gsap, Power2} from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import axiosInstance from "../helpers/axios";
import {findGetParameter} from "../helpers/find-get-param";
import {goToAnchor} from "../helpers/go-to-anchor";
import CodesWidget from "../components/codes/codes-widget";
import useEffectOnce from "../hooks/useEffectOnce";
import {pagination} from "../helpers/pagination";
import ChangePass from "../components/modals/ChangePass";
import Game from "../components/modals/Game";
import Login from "../components/modals/Login";
import Info from "../components/modals/Info";

gsap.registerPlugin(ScrollTrigger);

const faq = [
    {
        title: 'Кто является организатором акции?',
        answer: 'Организатором Акции является&nbsp;&mdash; Общество с&nbsp;ограниченной ответственностью &laquo;ТМА Маркетинг Сервисез&raquo; (юр. адрес: 115035, г. Москва, вн. тер.&nbsp;г. муниципальный округ Замоскворечье, наб. Космодамианская, д.&nbsp;52, стр.&nbsp;5, ИНН 7707614424, ОГРН 1077746003829, КПП 770501001), (далее&nbsp;&mdash; Организатор Акции).'
    },
    {
        title: 'Какие сроки проведения Акции?',
        answer: 'Общий срок проведения Акции с&nbsp;01&nbsp;октября по&nbsp;27&nbsp;декабря 2024 года включая выдачу призов.<br/>' +
            'Срок совершения покупки с&nbsp;01&nbsp;октября по&nbsp;24&nbsp;ноября 2024&nbsp;года.<br/>' +
            'Срок регистрации чеков с&nbsp;01&nbsp;октября по&nbsp;01&nbsp;декабря 2024&nbsp;года.'
    },
    {
        title: 'Где проводится Акция?',
        answer: 'Акция проводится в&nbsp;стационарных магазинах розничной продажи, осуществляющие торговую деятельность под обозначением &laquo;Магнит&raquo; (форматы: &laquo;Магнит у&nbsp;дома&raquo;, &laquo;Магнит Экстра&raquo;; &laquo;Магнит Семейный&raquo;, расположенных на&nbsp;территории Российской Федерации). Полный список магазинов торговой сети &laquo;Магнит&raquo; находится по&nbsp;адресу в&nbsp;сети Интернет <a target="_blank" href="https://magnit.ru/">https://magnit.ru/</a>'
    },
    {
        title: 'Кто может принять участие в&nbsp;Акции?',
        answer: 'Участниками могут быть дееспособные совершеннолетние граждане Российской Федерации, постоянно зарегистрированные на&nbsp;территории Российской Федерации или граждане Российской Федерации, достигшие на&nbsp;момент участия 18-летнего возраста.<br/>' +
            'Участниками не&nbsp;могут быть сотрудники и&nbsp;представители Организатора, Оператора, аффилированные с&nbsp;Организатором лица, члены их&nbsp;семей, а&nbsp;также работники других юридических лиц и/или индивидуальных предпринимателей, причастных к&nbsp;организации и&nbsp;проведению Акции, и&nbsp;члены их&nbsp;семей.'
    },
    {
        title: 'Как принять участие в&nbsp;Акции?',
        answer: 'Для участия в&nbsp;акции необходимо:<br/>' +
            '1. Посетить магазины розничной продажи, осуществляющие торговую деятельность под обозначением &laquo;Магнит&raquo; (форматы: &laquo;Магнит у&nbsp;дома&raquo;, &laquo;Магнит Экстра&raquo;; &laquo;Магнит Семейный&raquo;), расположенных на&nbsp;территории Российской Федерации; <br/>' +
            '2. Купить промо-продукцию Delicados; <br/>' +
            '3. Зарегистрировать кассовый чек следующим способом: пройти процедуру регистрации на&nbsp;Сайте <a target="_blank" href="https://delicados-promo.ru">https://delicados-promo.ru</a> или в&nbsp;чат-боте в&nbsp;мессенджере Telegram @delicados_promo_bot и&nbsp;загрузить копию или фотографию чека, отвечающую техническим требованиям, указанным в&nbsp;Правилах Акции, нажав на&nbsp;кнопку&nbsp;&mdash; &laquo;Зарегистрировать чек&raquo; или ввести данные чека вручную, используя форму &laquo;Ручной ввод&raquo;.'
    },
    {
        title: 'Какие Призы можно выиграть?',
        answer: '1.&nbsp;Гарантированный приз&nbsp;&mdash; игры для вечеринки (PDF-материал с&nbsp;лучшими играми для вечеринки: правила игр + карточки для распечатки). <br/>' +
            '2.&nbsp;Еженедельные призы:<br/>' +
            'Электронный сертификат &laquo;Магнит&raquo; номиналом 1 000,00 (одна тысяча) рублей 00&nbsp;копеек.<br/>' +
            'Электронный сертификат &laquo;Магнит&raquo; общим номиналом 2 000,00 (две тысячи) рублей 00&nbsp;копеек <br/>' +
            'Электронный сертификат &laquo;М.Видео&raquo; на&nbsp;покупку Портативной колонки номиналом 3 500,00 (три тысячи пятьсот) рублей 00&nbsp;копеек <br/>' +
            'Электронный сертификат &laquo;М.Видео&raquo; на&nbsp;покупку Пати-колонки номиналом 15 000,00 (пятнадцать тысяч) рублей 00&nbsp;копеек, а&nbsp;также денежная часть приза в&nbsp;размере 8 077,00 (восемь тысяч семьдесят семь) рублей 00&nbsp;копеек<br/>' +
            '3.&nbsp;Ежемесячный приз&nbsp;&mdash; электронный сертификат &laquo;М.Видео&raquo; на&nbsp;покупку Домашнего кинотеатра номиналом 250 000,00 (двести пятьдесят тысяч) рублей 00&nbsp;копеек, а&nbsp;также денежная часть приза в&nbsp;размере 134 615,00 (сто тридцать четыре тысячи шестьсот пятнадцать) рублей 00&nbsp;копеек<br/>' +
            '4.&nbsp;Специальные призы:<br/>' +
            'Бокс для вечеринки &laquo;Delicados&raquo; общей стоимостью 4 000,00 (четыре тысячи) рублей 00&nbsp;копеек.<br/>' +
            'Годовой запас Delicados на&nbsp;сумму 13&nbsp;631 (Тринадцать тысяч шестьсот тридцать один) рубль&nbsp;25 (двадцать пять) копеек, а&nbsp;также денежная часть приза в&nbsp;размере в&nbsp;размере 7&nbsp;340, 00&nbsp;рублей (Семь тысяч триста сорок) рублей 00&nbsp;копеек<br/>' +
            '5.&nbsp;Главный приз&nbsp;&mdash; денежный приз на&nbsp;проведение вечеринки в&nbsp;размере 500 000,00 (пятьсот тысяч) рублей 00&nbsp;копеек, а&nbsp;также дополнительная денежная часть приза в&nbsp;размере в&nbsp;размере 269 231,00 (Двести шестьдесят девять тысяч двести триста один) рубль 00&nbsp;копеек в&nbsp;количестве 1&nbsp;шт.'
    },
    {
        title: 'Нужно ли&nbsp;сохранять чек?',
        answer: 'Да, чек сохранять нужно до&nbsp;конца проведения Акции.'
    },
    {
        title: 'Сколько призов можно получить в&nbsp;этой акции?',
        answer: 'Один Участник может выиграть не&nbsp;более&nbsp;3 (трех) Гарантированных призов, 1&nbsp;(одного) Еженедельного приза или&nbsp;1 (одного) Ежемесячного, 2&nbsp;(двух) Специальных призов и&nbsp;1 (одного) Главного приза в&nbsp;течение всего периода проведения Акции.'
    },
    {
        title: 'Сколько раз можно получить гарантированный приз?',
        answer: 'Один зарегистрированный участник акции может получить гарантированный приз не&nbsp;более 3х&nbsp;раз.'
    },
    {
        title: 'Сколько ждать гарантированный приз?',
        answer: 'Отправка гарантированного приза осуществляется в&nbsp;течение 5&nbsp;рабочих дней с&nbsp;01&nbsp;октября 2024 года по&nbsp;29&nbsp;ноября 2024&nbsp;года.'
    },
    {
        title: 'Как принять участие в&nbsp;Тест-игре на&nbsp;сайте?',
        answer: '1. Купить акционный продукт, сохранив чек в&nbsp;период Акции.<br/>' +
            '2.Пройти регистрацию на&nbsp;Сайте или в&nbsp;чат-боте в&nbsp;мессенджере Telegram @delicados_promo_bot.<br/>' +
            '3. Зарегистрировать чек.<br/>' +
            '4. Нажать на&nbsp;кнопку &laquo;Играть сейчас!&raquo; на&nbsp;Сайте.<br/>' +
            '5. Смахивать верные изображения, связанные с&nbsp;брендом Delicados.<br/>' +
            '6. При этом Участнику игры дается&nbsp;34 (Тридцать четыре) пары карточек на&nbsp;весь период Игры.<br/>' +
            '7. Игра завершается автоматически, после прохождения всех карточек или раньше, если участник ответил на&nbsp;30&nbsp;вопросов.<br/>' +
            '8. Одна пара карточек = один день игры.'
    },
    {
        title: 'Что я&nbsp;могу получить за&nbsp;участие в&nbsp;Тест-игре на&nbsp;сайте?',
        answer: 'Если Участник игры ответил верно на&nbsp;30&nbsp;вопросов парных карточек, то&nbsp;он&nbsp;становится участником розыгрыша&nbsp;&mdash; годового запаса Delicados.'
    },
    {
        title: 'Что нужно сделать, чтобы получить Специальный приз&nbsp;&mdash; &laquo;Бокс для вечеринки&raquo;?',
        answer: '1. Купить определенный продукт Delicados: чипсы кукурузные «начос» со вкусом «Лобстер» с кусочками оливок и/или Delicados чипсы кукурузные «начос» со вкусом «Пикантная сальса» с кусочками перца чили халапеньо, сохранив чек в период Акции.<br/>' +
            '2. Пройти регистрацию на Сайте или в чат-боте в мессенджере Telegram @delicados_promo_bot.<br/>' +
            '3. Зарегистрировать чек.<br/>' +
            '4. После регистрации чека вы попадаете в розыгрыш.'
    },
    {
        title: 'Кто платит налог с&nbsp;призов стоимость которых превышает 4&nbsp;000&nbsp;рублей?',
        answer: 'При выдаче Приза Организатор Акции, исполняя обязанности налогового агента, исчисляет налог на&nbsp;доходы физических лиц и&nbsp;удерживает его из&nbsp;денежной части приза в&nbsp;соответствии с&nbsp;действующим налоговым законодательством Российской Федерации для перечисления его в&nbsp;бюджет соответствующего уровня.'
    },
    {
        title: 'Что делать, если возникли вопросы, ответов на&nbsp;которые нет в&nbsp;этом FAQ?',
        answer: 'Написать вопрос в&nbsp;разделе сайта &laquo;Ответы на&nbsp;вопросы&raquo; на&nbsp;электронную почту:<br/>' +
            '<a href="mailto:support@delicados-promo.ru">support@delicados-promo.ru</a>&nbsp;&mdash; по&nbsp;всем вопросам акции;<br/>' +
            '<a href="mailto:delicados-promo@tma-draft.com">delicados-promo@tma-draft.com</a>&nbsp;&mdash; только для взаимодействия с&nbsp;Победителями Акции по&nbsp;вручению Еженедельных, Ежемесячных, Специальных и&nbsp;Главных призов и&nbsp;обмена документами необходимыми для их&nbsp;получения, а&nbsp;также в&nbsp;случае возникновения вопросов по&nbsp;порядку получения, Еженедельных, Ежемесячных, Специальных и&nbsp;Главных призов и/или иной комбинации призов (по&nbsp;стоимости свыше 4&nbsp;тыс. руб.), которую в&nbsp;течение всего периода проведения Акции может получить Победитель.'
    },
    {
        title: 'Продукт участвующий в&nbsp;Акции',
        answer: '1.&nbsp;Delicados Чипсы кукурузные &laquo;начос&raquo; со&nbsp;вкусом сыра <br/>' +
            '2.&nbsp;Delicados Чипсы кукурузные &laquo;начос&raquo; с&nbsp;кусочками оливок и&nbsp;паприкой<br/>' +
            '3.&nbsp;Delicados Чипсы кукурузные &laquo;начос&raquo; со&nbsp;вкусом &laquo;Пикантная сальса&raquo; с&nbsp;кусочками перца чили халапеньо<br/>' +
            '4.&nbsp;Delicados Чипсы кукурузные &laquo;начос&raquo; со&nbsp;вкусом &laquo;Лобстер&raquo; с&nbsp;кусочками оливок<br/>' +
            '5.&nbsp;Delicados Чипсы кукурузные &laquo;начос&raquo; оригинальные'
    },
];
const products = [
    {
        title: 'DELICADOS NACHOS «НЕЖНЕЙШИЙ СЫР»',
        img: require("../assets/img/products/1.png"),
        infoTitle: 'Нежнейший сыр',
        infoText: 'Насладись аутентичными мексиканскими Delicados Nachos с&nbsp;разнообразными вкусами и&nbsp;наполни свою жизнь яркими, позитивными эмоциями! <br/><br/>Delicados&nbsp;&mdash; &laquo;Нежнейший сыр&raquo;&nbsp;&mdash; это классические начос в&nbsp;сочетании с&nbsp;насыщенным сырным вкусом. Чипсы изготовлены исключительно из&nbsp;цельного зерна кукурузы, сваренного по&nbsp;особой технологии и&nbsp;размолотого каменными жерновами, с&nbsp;обжаркой на&nbsp;высокоолеиновом подсолнечном масле. Такой способ производства придает нашим хрустящим начос особый вкус!'
    },
    {
        title: 'DELICADOS NACHOS «Пикантная сальса» с&nbsp;кусочками перца чили',
        img: require("../assets/img/products/2.png"),
        dop: true,
        infoTitle: 'Пикантная сальса с&nbsp;кусочками острого чили',
        infoText: 'Насладись путешествием по&nbsp;странам мира с&nbsp;нашей новинкой&nbsp;&mdash; попробуй Delicados Nachos &laquo;Лобстер&raquo; с&nbsp;кусочками настоящих оливок и&nbsp;ощути вкус средиземноморского отдыха!'
    },
    {
        title: 'DELICADOS NACHOS <br/>«Лобстер» с&nbsp;кусочками оливок',
        img: require("../assets/img/products/3.png"),
        dop: true,
        infoTitle: 'ЛОБСТЕР с&nbsp;кусочками оливок',
        infoText: 'Насладись путешествием по&nbsp;странам мира с&nbsp;нашей новинкой&nbsp;&mdash; попробуй Delicados Nachos &laquo;Лобстер&raquo; с&nbsp;кусочками настоящих оливок и&nbsp;ощути вкус средиземноморского отдыха!'
    },
    {
        title: 'DELICADOS NACHOS ОРИГИНАЛЬНЫЕ',
        img: require("../assets/img/products/4.png"),
        infoTitle: 'Оригинальные',
        infoText: 'Насладись аутентичными мексиканскими Delicados Nachos с&nbsp;разнообразными вкусами и&nbsp;наполни свою жизнь яркими, позитивными эмоциями! <br/><br/>Delicados Оригинальные&nbsp;&mdash; это классические начос с&nbsp;добавлением морской соли, традиционной треугольной формы. Они изготовлены исключительно из&nbsp;цельного зерна кукурузы, сваренного по&nbsp;особой технологии и&nbsp;размолотого каменными жерновами, с&nbsp;обжаркой на&nbsp;высокоолеиновом подсолнечном масле. Такой способ производства придает нашим хрустящим чипсам особый насыщенный вкус!'
    },
    {
        title: 'DELICADOS NACHOS С&nbsp;КУСОЧКАМИ ОЛИВОК И&nbsp;ПАПРИКОЙ',
        img: require("../assets/img/products/5.png"),
        infoTitle: 'С&nbsp;кусочками оливок и&nbsp;паприкой',
        infoText: 'Насладись аутентичными мексиканскими Delicados Nachos с&nbsp;разнообразными вкусами и&nbsp;наполни свою жизнь яркими, позитивными эмоциями! <br/><br/>Delicados с&nbsp;кусочками оливок и&nbsp;паприкой&nbsp;&mdash; это классические начос в&nbsp;сочетании с&nbsp;пряно-сладкой паприкой и&nbsp;испанскими оливками. Чипсы изготовлены исключительно из&nbsp;цельного зерна кукурузы, сваренного по&nbsp;особой технологии и&nbsp;размолотого каменными жерновами, с&nbsp;обжаркой на&nbsp;высокоолеиновом подсолнечном масле. Такой способ производства придает нашим хрустящим начос особый вкус!'
    },
];
const periods = [
    {
        title: '01.10.2024 - 06.10.2024',
        start: '2024-10-01',
        end: '2024-10-06'
    },
    {
        title: '07.10.2024 - 13.10.2024',
        start: '2024-10-07',
        end: '2024-10-13'
    },
    {
        title: '14.10.2024 - 20.10.2024',
        start: '2024-10-14',
        end: '2024-10-20'
    },
    {
        title: '21.10.2024 - 27.10.2024',
        start: '2024-10-21',
        end: '2024-10-27'
    },
    {
        title: '28.10.2024 - 03.11.2024',
        start: '2024-10-28',
        end: '2024-11-03'
    },
    {
        title: '04.11.2024 - 10.11.2024',
        start: '2024-11-04',
        end: '2024-11-10'
    },
    {
        title: '11.11.2024 - 17.11.2024',
        start: '2024-11-11',
        end: '2024-11-17'
    },
    {
        title: '18.11.2024 - 24.11.2024',
        start: '2024-11-18',
        end: '2024-11-24'
    }
];
const winPrizes = {
    1: require("../assets/img/win-prizes/1.png"),
    2: require("../assets/img/win-prizes/2.png"),
    3: require("../assets/img/win-prizes/3.png"),
    4: require("../assets/img/win-prizes/4.png"),
    5: require("../assets/img/win-prizes/5.png"),
    6: require("../assets/img/win-prizes/6.png"),
    7: require("../assets/img/win-prizes/7.png"),
    8: require("../assets/img/win-prizes/8.png"),
    9: require("../assets/img/win-prizes/9.png")
}
const perPage = 7;

function Home() {
    const [faqCur, setFaqCur] = useState(0);
    const [faqFull, setFaqFull] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [period, setPeriod] = useState({
        start: null,
        end: null
    });
    const [periodTitle, setPeriodTitle] = useState('');
    const [prizeTab, setPrizeTab] = useState('garant');
    const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = useState(false);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winners, setWinners] = useState([]);
    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });
    const [productModal, setProductModal] = useState(null);
    const {profile} =  useProfile();
    const {end} = useEnd();
    const [loading, setLoading] = useState(false);

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    const { show, hide} = useModal();

    const [curSl1, setCurSl1] = useState(0);
    const [loadedSl1, setLoadedSl1] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        loop: true,
        slides: {
            perView: 5,
            spacing: 0,
        },
        breakpoints: {
            '(max-width: 1023px)': {
                slides: {
                    perView: 2,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {
            setCurSl1(slider.track.details.rel);
            setProductModal(null);
        },
        created() {
            setLoadedSl1(true)
        },
    });

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');
            let pass = findGetParameter('pass');
            if(parseInt(result) === 0 && auth_key !== null){
                show(<Reg finish={true} token={auth_key}/>, "modal--form modal--reg");
                window.history.pushState({param: 'Value'}, '', '/');
            }
            if(parseInt(result) === 1 && auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                if(parseInt(pass) !== 1){
                    window.location.href = process.env.PUBLIC_URL + '/cabinet';
                }
            }

            if(parseInt(pass) === 1 && auth_key !== null) {
                show(<ChangePass/>, "modal--form modal--pass");
                window.history.pushState({param: 'Value'}, '', '/');
            }
        }

        if(window.location.hash){
            goToAnchor(window.location.hash.substring(1));
            window.history.pushState({param: 'Value'}, '', '/');
        }
    },[]);

    useEffectOnce(()=>{
        getWinners(1, false);
    });

    useEffect(()=>{
        if(period.start){
            setValue('email', '');
            getWinners(1, false);
        }
    }, [period]);

    useEffect(() => {
        if(window.matchMedia("(min-width: 1024px)").matches){
            document.addEventListener("mousemove", parallax);
        }
    }, []);

    useLayoutEffect(()=>{
        animation();
    }, []);

    const animation = () => {
        const prizesBoxes = document.querySelectorAll('.fade-anim');
        prizesBoxes.forEach((box) => {
            gsap.to(box, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: box,
                    start: 'top-=' + window.innerHeight * 0.7 + 'px',
                },
                delay: box.dataset.delay ?? 0,
                onComplete: ()=> {
                    if(box.classList.contains('steps__it-img')){
                        box.classList.add('completed');
                    }
                }
            })
        });

    }

    const parallax = (event) => {
        const shiftX = event.pageX - window.innerWidth / 2;
        const shiftY = event.pageY - window.innerHeight / 2;

        document.querySelectorAll(".parallax-mouse").forEach((plxIt, i) => {
            let request = null;
            const position = plxIt.getAttribute("data-parallax-value");
            const x = shiftX * position * 0.025;
            const y = shiftY * position * 0.025;
            cancelAnimationFrame(request);
            request = requestAnimationFrame(update);

            function update() {
                gsap.to(plxIt, {
                    x: x,
                    y: y,
                    ease: Power2.easeOut
                });
            }
        });
    }

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        setPeriod({
            start: null,
            end: null
        });
        getWinners(1, true);
    }

    const getWinners = async(page = 1, search = null) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('/prizes/api/winners?per-page=' + perPage + '&page=' + page + (search ? '&filter[and][1][user_email][like]=' + watchEmail : '') + ((period.start && !search) ? '&filter[and][1][use_at][gte]=' + period.start + '+00:00:00&filter[and][1][use_at][lte]=' + period.end + '+23:59:59' : ''));
            if(response.data){
                setWinners(response.data.items);
                setWinPaging({
                    total: response.data._meta.pageCount,
                    current_page: response.data._meta.currentPage
                });
                setLoading(false);
                setSearchLoading(false);

                if(search){
                    setWinnersEmpty('Среди победителей данный аккаунт не найден');
                }else{
                    setWinnersEmpty('Победители не определены');
                }
            }
        } catch (error) {
            if(error.response && error.response.data){

            }

            setLoading(false);
            setSearchLoading(false);
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2L3 11L12 20" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="pager__list">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(winPaging.current_page !== parseInt(page)){
                                        getWinners(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L11 11L2 20" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className="content">
                <div id="promo" className="sc__promo">
                    <div className="promo__bg">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/promo-bg.jpg")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/promo-bg-sm.jpg")}/>
                            <img width="1600"
                                 height="900"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>
                        <div className="promo__bg-over desk-only">
                            <svg viewBox="0 0 1600 902" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M535 598.929C344.6 650.049 28.3333 629.019 -140 612.114V902H1601V-177H1240C1240 -177 1167.8 41.2743 1067 184.086C941 362.6 773 535.029 535 598.929Z" fill="#FF0000"/>
                            </svg>
                        </div>
                    </div>
                    <div className="promo__glow desk-only">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/promo-glow.png")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/promo-glow.png")}/>
                            <img width="1060"
                                 height="868"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>
                    </div>
                    <div className="promo__products parallax-mouse" data-parallax-value="0.5">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/promo-products.png")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/promo-products.png")}/>
                            <img width="871"
                                 height="600"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>

                        <div onClick={()=>{
                            goToAnchor('special');
                        }} className="promo__products-action parallax-mouse" data-parallax-value="0.5">
                            <div className="promo__products-action-title">
                                Специальный розыгрыш при <br/>
                                покупке новинок от Delicados
                            </div>
                            <div className="promo__products-action-arrow">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5625" cy="17.5625" r="17" transform="rotate(90 17.5625 17.5625)" fill="black"/>
                                    <path d="M17.0322 23.7592C17.3251 24.0521 17.7999 24.0521 18.0928 23.7592L22.8658 18.9863C23.1587 18.6934 23.1587 18.2185 22.8658 17.9256C22.5729 17.6327 22.098 17.6327 21.8051 17.9256L17.5625 22.1682L13.3199 17.9256C13.027 17.6327 12.5521 17.6327 12.2592 17.9256C11.9663 18.2185 11.9663 18.6934 12.2592 18.9863L17.0322 23.7592ZM16.8125 9.62891V23.2289H18.3125V9.62891H16.8125Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="promo__decor-1 desk-only parallax-mouse" data-parallax-value="0.5"/>
                    <div className="promo__decor-2 parallax-mouse" data-parallax-value="-0.5"/>
                    <div className="promo__decor-3 desk-only parallax-mouse" data-parallax-value="-0.5"/>
                    <div className="promo__decor-4 desk-only parallax-mouse" data-parallax-value="-0.35"/>
                    <div className="promo__decor-mb-1 mb-only"/>
                    <div className="promo__decor-mb-2 mb-only"/>
                    <div className="promo__decor-mb-3 mb-only"/>
                    <div className="container">
                        <div className="promo__ct">
                            <div className="promo__title parallax-mouse" data-parallax-value="-0.3">
                                <span>Магнит для вечеринки</span>
                            </div>
                            <div className="promo__subtitle parallax-mouse" data-parallax-value="0.35">
                                Устройте яркое гастрономическое путешествие вместе с&nbsp;Delicados!

                                <div className="action__fixed action__fixed--promo">
                                    <div className="action__fixed-title">
                                        Зарегистрировать чек
                                    </div>
                                    <CodesWidget classesList="action__fixed-btn" module="main"/>
                                    <a onClick={()=>{
                                        if(window.ym){
                                            window.ym(97392085,'reachGoal','кнопка Перехода в мессенджер');
                                        }
                                    }} href={'https://t.me/delicados_promo_bot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.promocode : '?start=NEW')} target="_blank" className="promo__btn-tg">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.8121 0.40884C8.9921 0.347812 0.171475 9.0695 0.110447 19.8895C0.049419 30.7094 8.7711 39.53 19.5911 39.5911C30.411 39.6521 39.2316 30.9304 39.2927 20.1105C39.3537 9.2905 30.632 0.469868 19.8121 0.40884Z" fill="#40B3E0"/>
                                            <path d="M29.2803 11.7343L25.6816 29.3568C25.6816 29.3568 25.1854 30.5776 23.8429 29.9827L15.8029 23.7464L12.8747 22.3107L7.94131 20.6188C7.94131 20.6188 7.18428 20.3455 7.11413 19.7578C7.04413 19.1701 7.97573 18.8573 7.97573 18.8573L27.6679 11.2602C27.6679 11.2602 29.2869 10.5598 29.2803 11.7343Z" fill="white"/>
                                            <path d="M15.1541 29.0965C15.1541 29.0965 14.9185 29.0731 14.63 28.1415C14.3417 27.21 12.8767 22.3078 12.8767 22.3078L24.7867 14.8383C24.7867 14.8383 25.4743 14.4261 25.4475 14.842C25.4475 14.842 25.5693 14.916 25.2004 15.2565C24.8314 15.5971 15.8303 23.5968 15.8303 23.5968" fill="#D2E5F1"/>
                                            <path d="M18.8873 26.1344L15.6769 29.0284C15.6769 29.0284 15.4262 29.2165 15.1537 29.0961L15.7958 23.6905" fill="#B5CFE4"/>
                                        </svg>

                                    </a>
                                </div>
                            </div>
                            <div className="promo__prize parallax-mouse" data-parallax-value="-0.25">
                                <img src={require("../assets/img/promo-main-prize.png")}/>
                                <div className="promo__prize-title-1">
                                    <svg width="233" height="62" viewBox="0 0 233 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.3899 59.5029L2.5369 2.01263L230.757 2.51967L230.767 59.5428L2.3899 59.5029Z" fill="#FED731" stroke="white" strokeWidth="3"/>
                                    </svg>
                                    <span>главный приз —</span>
                                </div>
                                <div className="promo__prize-title-2">
                                    <svg width="304" height="112" viewBox="0 0 304 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M301.712 110.443H91.1284L91.1602 57.77L91.1612 56.2691H89.6602H1.94469L1.96613 2.26904H201.183V43.9429V45.4429H202.683H301.765L301.712 110.443Z" fill="#FED731" stroke="white" strokeWidth="3"/>
                                    </svg>
                                    <span>вечеринка на</span>
                                    <span>500 000 ₽</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__steps">
                    <div id="steps" className="anchor"/>
                    <div className="steps__decor-1"/>
                    <div className="sc__sep"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Как выиграть призы
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        <div className="steps__list">
                            <div className="steps__it steps__it--1">
                                <div className="steps__it-img-wrap">
                                    <div className="steps__it-img fade-anim">
                                        <img src={require("../assets/img/st-1.png")}/>
                                    </div>
                                    <div className="steps__it-numb">
                                        1
                                    </div>
                                </div>
                                <div className="steps__it-title">
                                    Покупайте
                                </div>
                                <div className="steps__it-descr">
                                    Delicados в&nbsp;Магните
                                </div>
                                <div className="steps__it-logo">
                                    <img src={require("../assets/img/magnit-small.svg").default}/>
                                </div>
                            </div>
                            <div className="steps__it steps__it--2">
                                <div className="steps__it-img-wrap">
                                    <div className="steps__it-img fade-anim" data-delay={0.1}>
                                        <img src={require("../assets/img/st-2.png")}/>
                                    </div>
                                    <div className="steps__it-numb">
                                        2
                                    </div>
                                </div>
                                <div className="steps__it-title">
                                    Регистрируйте
                                </div>
                                <div className="steps__it-descr">
                                    чеки на&nbsp;сайте <br className="desk-only"/>
                                    или в&nbsp;Telegram
                                </div>
                            </div>
                            <div className="steps__it steps__it--3">
                                <div className="steps__it-img-wrap">
                                    <div className="steps__it-img fade-anim" data-delay={0.2}>
                                        <img src={require("../assets/img/st-3.png")}/>
                                    </div>
                                    <div className="steps__it-numb">
                                        3
                                    </div>
                                </div>
                                <a onClick={()=>{
                                    goToAnchor('prizes');
                                }}>
                                    <div className="steps__it-title">
                                        получите
                                    </div>
                                    <div className="steps__it-descr">
                                        гарантированный <br className="desk-only"/>
                                        подарок
                                    </div>
                                </a>
                            </div>
                            <div className="steps__it steps__it--4">
                                <div className="steps__it-img-wrap">
                                    <div className="steps__it-img fade-anim" data-delay={0.3}>
                                        <img src={require("../assets/img/st-4.png")}/>
                                    </div>
                                    <div className="steps__it-numb">
                                        4
                                    </div>
                                </div>
                                <a onClick={()=>{
                                    goToAnchor('prizes');
                                }}>
                                    <div className="steps__it-title">
                                        выигрывайте
                                    </div>
                                    <div className="steps__it-descr">
                                        призы
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__special">
                    <div id="special" className="anchor"/>
                    <div className="special__decor-1"/>
                    <div className="special__decor-2"/>
                    <div className="container">
                        <div className="special__wrap">
                            <div className="special__img">
                                <img src={require("../assets/img/special-img.png")}/>
                            </div>
                            <div className="special__ct">
                                <div className="special__title">
                                    Специальный розыгрыш <br/>
                                    при покупке новинок <br/>
                                    от Delicados
                                </div>
                                <div className="special__descr">
                                    Попробуйте яркие вкусы Delicados &laquo;Лобстер&raquo; с&nbsp;кусочками оливок и&nbsp;&laquo;Пикантная сальса&raquo; с&nbsp;кусочками перца чили и&nbsp;участвуйте в&nbsp;специальном розыгрыше <a>большого party-set</a>
                                </div>
                                <div className="special__list">
                                    <ul>
                                        <li>
                                            Delicados Nachos, соусы, тортильи
                                        </li>
                                        <li>
                                            Яркий праздничный аксессуар
                                        </li>
                                        <li>
                                            Подписка на&nbsp;Яндекс.Плюс на&nbsp;1&nbsp;год
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__prizes">
                    <div id="prizes" className="anchor"/>
                    <div className="bg__top"/>
                    <div className="bg__texture"/>
                    <div className="prizes__decor-3 fade-anim"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Выигрывайте призы
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        <div className="prizes__box">
                            <div className="prizes__box-group prizes__box-group--main fade-anim">
                                <div className="prizes__decor-2"/>
                                <div className="prizes__box-group-hd mb-only">
                                    <div className="prizes__box-group-title">
                                        Главный приз
                                    </div>
                                </div>
                                <div className="prizes__box-group-ct">
                                    <div className="prizes__box-left">
                                        <div className="prizes__box-group-hd desk-only">
                                            <div className="prizes__box-group-title">
                                                Главный приз
                                            </div>
                                        </div>
                                        <div className="prizes__box-title">
                                            Вечеринка на
                                            500&nbsp;000&nbsp;рублей
                                        </div>
                                        <div className="prizes__box-hint">
                                            Количество призов: 1 шт.
                                        </div>
                                    </div>
                                    <div className="prize__img-1">
                                        <img src={require("../assets/img/prize-main-img.png")}/>
                                        <div className="prizes__decor-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="fade-in">
                                <div className="prizes__scroll-list">
                                    <div className="prizes__scroll">
                                        <div onClick={()=>{
                                            setPrizeTab('garant');
                                        }} className={'prizes__scroll-it' + (prizeTab === 'garant' ? ' active' : '')}>
                                            Гарантированный приз
                                        </div>
                                        <div onClick={()=>{
                                            setPrizeTab('week');
                                        }} className={'prizes__scroll-it' + (prizeTab === 'week' ? ' active' : '')}>
                                            Еженедельные призы
                                        </div>
                                        <div onClick={()=>{
                                            setPrizeTab('month');
                                        }} className={'prizes__scroll-it' + (prizeTab === 'month' ? ' active' : '')}>
                                            Ежемесячный приз
                                        </div>
                                        <div onClick={()=>{
                                            setPrizeTab('special');
                                        }} className={'prizes__scroll-it' + (prizeTab === 'special' ? ' active' : '')}>
                                            Специальные призы
                                        </div>
                                    </div>
                                </div>
                                <div className={'prizes__box-group prizes__box-group--garant fadeIn animated' + (prizeTab === 'garant' ? ' active' : '')}>
                                    <div className="prizes__box-group-ct">
                                        <div className="prizes__box-left">
                                            <div className="prizes__box-left-ct">
                                                <div className="prizes__box-title-2">
                                                    Игры для вечеринки
                                                </div>
                                                <div className="prizes__box-descr">
                                                    <ul>
                                                        <li>
                                                            Baile Loco (Танцевальная дуэль)
                                                        </li>
                                                        <li>
                                                            Lucha de Risas (Битва Смеха)
                                                        </li>
                                                        <li>
                                                            Foto Fiesta (Мексиканские Папарацци)
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes__box-img">
                                            <img src={require("../assets/img/prize-garant.png")}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'prizes__box-group prizes__box-group--week fadeIn animated' + (prizeTab === 'week' ? ' active' : '')}>
                                    <div className="prizes__list">
                                        <div className="prizes__it">
                                            <div className="prizes__it-img">
                                                <img src={require("../assets/img/pr-w-1.png")}/>
                                            </div>
                                            <div className="prizes__it-title">
                                                Электронные
                                                сертификаты
                                                «Магнит»
                                            </div>
                                            <div className="prizes__it-descr">
                                                3 шт. каждого вида в неделю
                                            </div>
                                        </div>
                                        <div className="prizes__it">
                                            <div className="prizes__it-img">
                                                <img src={require("../assets/img/pr-w-2.png")}/>
                                            </div>
                                            <div className="prizes__it-title">
                                                Сертификат <br className="desk-only"/>
                                                М.Видео на&nbsp;покупку портативной колонки
                                            </div>
                                            <div className="prizes__it-descr">
                                                3 шт. в неделю
                                            </div>
                                        </div>
                                        <div className="prizes__it">
                                            <div className="prizes__it-img">
                                                <img src={require("../assets/img/pr-w-3.png")}/>
                                            </div>
                                            <div className="prizes__it-title">
                                                Сертификат <br className="desk-only"/>
                                                М.Видео на&nbsp;покупку Party-колонки
                                            </div>
                                            <div className="prizes__it-descr">
                                                1 шт. в неделю
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'prizes__box-group prizes__box-group--month fadeIn animated' + (prizeTab === 'month' ? ' active' : '')}>
                                    <div className="prizes__box-group-ct">
                                        <div className="prizes__box-left">
                                            <div className="prizes__box-left-ct">
                                                <div className="prizes__box-title-2">
                                                    Сертификат М.Видео <br/>
                                                    на&nbsp;покупку Домашнего <br/>
                                                    кинотеатра
                                                </div>
                                                <div className="prizes__it-descr">
                                                    Количество призов: 2 шт.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes__box-img">
                                            <img src={require("../assets/img/pr-m-1.png")}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'prizes__box-group prizes__box-group--special fadeIn animated' + (prizeTab === 'special' ? ' active' : '')}>
                                    <div className="prizes__list prizes__list--special">
                                        <div className="prizes__it">
                                            <div className="prizes__it-img">
                                                <img src={require("../assets/img/pr-sp-1.png")}/>
                                            </div>
                                            <div className="prizes__it-title">
                                                Годовой запас Delicados
                                            </div>
                                            <div className="prizes__it-subtitle">
                                                Для участников игры-теста
                                            </div>
                                            <div className="prizes__it-descr">
                                                1 шт. за весь период Акции
                                            </div>
                                        </div>
                                        <div className="prizes__it">
                                            <div className="prizes__it-img">
                                                <img src={require("../assets/img/pr-sp-2.png")}/>
                                            </div>
                                            <div className="prizes__it-title">
                                                Бокс для вечеринки Delicados: <br/>Nachos, соусы, тортильи <br/><br className="mb-only"/>
                                                Яркий праздничный аксессуар <br/><br className="mb-only"/>
                                                Подписка на&nbsp;Яндекс.Плюс на&nbsp;1&nbsp;год
                                            </div>
                                            <div className="prizes__it-subtitle">
                                                Для покупателей Delicados &laquo;Лобстер&raquo; с&nbsp;кусочками оливок и&nbsp;&laquo;Пикантная сальса&raquo; с&nbsp;кусочками перца чили
                                            </div>
                                            <div className="prizes__it-descr">
                                                1 шт. в неделю
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__game">
                    <div id="game" className="anchor"/>
                    <div className="sc__sep"/>
                    <div className="sc__bg-top">
                        <svg className="desk-only" viewBox="0 0 1600 141" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                            <path d="M0 50C0 50 336.022 0 800 0C1263.98 0 1600 50 1600 50V141H0V50Z" fill="#FF0000"/>
                        </svg>
                        <svg className="mb-only" viewBox="0 0 360 32" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                            <path d="M0 11.25C0 11.25 75.605 0 180 0C284.395 0 360 11.25 360 11.25V31.725H0V11.25Z" fill="#FF0000"/>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Отвечайте на&nbsp;вопросы <br/>
                                и выигрывайте <br className="mb-only"/>вкусные призы!
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        {!profile.isLoading && (!profile.loggedIn || (profile.loggedIn && profile.data.last_question_number < 34)) && (
                            <div className="game__box fadeIn animated">
                                <div className="game__box-left">
                                    <div className="game__box-list">
                                        <div className="game__box-it">
                                            <div className="game__box-it-numb">
                                                1
                                            </div>
                                            <div className="game__box-it-descr">
                                                Перед тобой&nbsp;&mdash; пары картинок с&nbsp;вопросами о&nbsp;Delicados Nachos и&nbsp;обо всём, что с&nbsp;ними связано. Смахивай изображения в&nbsp;нужные стороны и&nbsp;смотри не&nbsp;перепутай, ведь самых внимательных ждёт награда!
                                            </div>
                                        </div>
                                        <div className="game__box-it">
                                            <div className="game__box-it-numb">
                                                2
                                            </div>
                                            <div className="game__box-it-descr">
                                                Возвращайтесь за&nbsp;новым вопросом каждый день. Сделать выбор между картинками нужно не&nbsp;менее 30&nbsp;раз.
                                            </div>
                                        </div>
                                        <div className="game__box-it">
                                            <div className="game__box-it-numb">
                                                3
                                            </div>
                                            <div className="game__box-it-descr">
                                                Участвуйте в розыгрыше <br/>
                                                Годового запаса Delicados!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="game__box-after mb-only">
                                        <img src={require("../assets/img/game-img-products.png")} loading="lazy"/>
                                    </div>
                                    <div className="game__box-left-action">
                                        <div onClick={()=>{
                                            if(profile.loggedIn) {
                                                if(profile.data.receipts_count > 0) {
                                                    show(<Game/>, "modal--game");
                                                }else{
                                                    show(<Info
                                                        icon={false}
                                                        title="Не&nbsp;выполнено условие"
                                                        text={"Для участия в&nbsp;тест-игре необходимо зарегистрировать чек"}
                                                        close={hide}/>, "modal--info");
                                                }
                                            }else{
                                                show(<Login/>, "modal--form modal--login");
                                            }
                                        }} className="btn">
                                            <span>Играть сейчас!</span>
                                            <i className="btn__arrow">
                                                <svg>
                                                    <use href="#btn-arrow"></use>
                                                </svg>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="game__box-right">
                                    <div className="game__img-photos">
                                        <picture>
                                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/game-img-photos.png")}/>
                                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/game-img-sm.png")}/>
                                            <img width="498"
                                                 height="370"
                                                 alt=""
                                                 draggable="false" loading="lazy"
                                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                                        </picture>
                                    </div>
                                    <div className="game__img-products desk-only">
                                        <img src={require("../assets/img/game-img-products.png")} loading="lazy" draggable="false"/>
                                    </div>
                                    <div className="game__box-decor"/>
                                </div>
                            </div>
                        )}

                        {!profile.isLoading && profile.loggedIn && profile.data.last_question_number === 34 && (
                            <div className="game__box game__box--passed fadeIn animated">
                                <div className="game__box-decor"/>
                                <div className="game__passed-img">
                                    <img src={require("../assets/img/game-passed-img.png")} draggable={false} loading="lazy"/>

                                    <div className="game__passed-decor-1"/>
                                    <div className="game__passed-decor-2"/>
                                </div>
                                <div className="game__passed-title">
                                    Поздравляем! <br/>
                                    Вы прошли всю игру, ожидайте <br/>
                                    розыгрыша Специального приза- <br/>
                                    <span>Годового запаса Delicados!</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="sc__products">
                    <div id="products" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Продукты, участвующие в&nbsp;акции
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        <div className="products__sl-box">
                            <div onClick={()=>{
                                instanceRef.current?.next();
                            }} className="sl__nav sl__nav--next mb-only">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" fill="white"/>
                                    <path d="M14.5 11.7002L18.4 15.6002L14.5 19.5002" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <div onClick={()=>{
                                instanceRef.current?.prev();
                            }} className="sl__nav sl__nav--prev mb-only">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                    <path d="M15.5 11.7002L11.6 15.6002L15.5 19.5002" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <div className="products__sl-row">
                                <div className="products__sl">
                                    <div ref={sliderRef} className="keen-slider">
                                        {products.map((pr, i) => (
                                            <div className="keen-slider__slide">
                                                <a href="https://www.delicados.ru/" target="_blank" className="products__sl-it">
                                                    <div className="products__sl-it-img">
                                                        <img src={pr.img}/>

                                                        {pr.dop && (
                                                            <div className="products__sl-it-new"/>
                                                        )}
                                                    </div>
                                                    <div className="products__sl-it-ct">
                                                        <div className="products__sl-it-title">
                                                            {parse(pr.title)}
                                                        </div>
                                                        {pr.dop && (
                                                            <div className="products__sl-it-info">
                                                                Участвует в розыгрыше <br/>
                                                                дополнительных призов
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="products__sl-it-details desk-only">
                                                        <div className="products__sl-it-details-title">
                                                            {parse(pr.infoTitle)}
                                                        </div>
                                                        <div className="products__sl-it-details-text">
                                                            {parse(pr.infoText)}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {productModal !== null && (
                                <div className="products__sl-it-details fadeIn animated mb-only">
                                    <div onClick={()=>{
                                        setProductModal(null);
                                    }} className="products__sl-it-details-close">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L11 11" stroke="black" strokeWidth="2"/>
                                            <path d="M11 1L1 11" stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                    <div className="products__sl-it-details-title">
                                        {parse(products[productModal].infoTitle)}
                                    </div>
                                    <div className="products__sl-it-details-text">
                                        {parse(products[productModal].infoText)}
                                    </div>
                                </div>
                            )}

                            {loadedSl1 && instanceRef.current && (
                                <div className="dots mb-only">
                                    {[
                                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRef.current?.moveToIdx(idx)
                                                }}
                                                className={"dot" + (curSl1 === idx ? " active" : "")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sc__winners">
                    <div id="winners" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Победители
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        <div className="winners__box">
                            <div className="winners__box-filters">
                                <div className="winners__box-filters-left">
                                    <div className={'form__select' + (isPeriodDropdownOpen ? ' active' : '')}>
                                        <div onClick={()=>{
                                            setIsPeriodDropdownOpen(!isPeriodDropdownOpen);
                                        }} className="form__select-input">
                                            <div className={'form__select-value' + (!period.start ? ' form__select-value--placeholder' : '')}>
                                                {period.start ? periodTitle : 'Выберите'}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg className="desk-only" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 2L9 9L16 2" stroke="black" strokeWidth="3" strokeLinecap="round"/>
                                                </svg>
                                                <svg className="mb-only" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1.37579L7 7.37579L13 1.37579" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__select-dropdown-list">
                                                {periods.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsPeriodDropdownOpen(false);
                                                        setPeriodTitle(it.title);
                                                        setPeriod(it);
                                                    }} className={'form__select-dropdown-it' + (it.start === period.start ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="win__form-label">
                                        Выберите неделю, чтобы посмотреть победителей
                                    </div>
                                </div>
                                <div className="win__search-bl">
                                    <form className={'win__search' + (searchLoading ? ' form--loading' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                        <div className={'win__search-field' + (errors.email ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                id="email-search"
                                                placeholder="Поиск по электронной почте"
                                                {...register("email")}
                                            />
                                        </div>
                                        <button type="submit" className="search__submit">
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.6878 18.5794L24 24M18.5314 10.5725C18.5314 15.307 14.8307 19.1451 10.2657 19.1451C5.70067 19.1451 2 15.307 2 10.5725C2 5.83806 5.70067 2 10.2657 2C14.8307 2 18.5314 5.83806 18.5314 10.5725Z" stroke="#212121" strokeWidth="3"/>
                                            </svg>
                                        </button>
                                    </form>
                                    <div className="win__form-label">
                                        {errors.email ? errors.email?.message : 'Введите вашу почту'}
                                    </div>
                                </div>
                            </div>
                            <div className="winners__box-wrap">
                                <div className="winners__decor-1 fade-anim"/>
                                <div className="winners__decor-2 fade-anim"/>
                                {winners.length > 0 && (
                                    <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                        <div className="tb__head desk-only">
                                            <div className="tb__row">
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        имя
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        e-mail
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        дата
                                                    </div>
                                                </div>

                                                <div className="tb__col">
                                                    <div className="tb__th">
                                                        приз
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tb__body">
                                            {winners.length > 0 && winners.map((win, i) => (
                                                <div className="tb__row">
                                                    <div className="tb__col" data-title={'Имя'}>
                                                        <div className="tb__td tb__td--name">
                                                            {win.user_name}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title={'E-mail'}>
                                                        <div className="tb__td">
                                                            {win.user_email}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title={'Дата'}>
                                                        <div className="tb__td">
                                                            {win.use_at}
                                                        </div>
                                                    </div>
                                                    <div className="tb__col" data-title={'Приз'}>
                                                        <div className="tb__td tb__td--prize">
                                                            <div className="tb__img">
                                                                <img src={winPrizes[win.type]} draggable="false" loading="lazy"/>
                                                            </div>
                                                            <span>{win.type_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {winners.length === 0 && (
                                    <div className={'tb__empty' + (loading ? ' tb--loading' : '')}>
                                        {winnersEmpty}
                                    </div>
                                )}
                            </div>
                            {winPaging.total > 1 && (
                                renderPager()
                            )}
                        </div>
                    </div>
                </div>
                <div className="sc__faq">
                    <div className="bg__top"/>
                    <div className="bg__texture"/>
                    <div id="faq" className="anchor"/>
                    <div id="buy" className="anchor anchor--buy"/>
                    <div className="faq__decor-1"/>
                    <div className="faq__decor-2"/>
                    <div className="faq__decor-3"/>
                    <div className="faq__decor-4"/>
                    <div className="faq__decor-5"/>
                    <div className="faq__decor-6"/>
                    <div className="faq__decor-7"/>
                    <div className="container">
                        <div className="shop__banner">
                            <div className="shop__banner-logo">
                                <img src={require("../assets/img/magnit.svg").default}/>
                            </div>
                            <a href="https://redirect.appmetrica.yandex.com/serve/893662341932054611" target="_blank" className="btn">
                                <span>Где купить</span>
                                <i className="btn__arrow">
                                    <svg>
                                        <use href="#btn-arrow"></use>
                                    </svg>
                                </i>
                            </a>
                        </div>
                        <div className="sc__header">
                            <div className="sc__title">
                                Вопросы и ответы
                            </div>
                            <div className="sc__header-sep">
                                <svg>
                                    <use href="#title-sep"></use>
                                </svg>
                            </div>
                        </div>
                        <div className="faq__wrap">
                            <div className="faq__left">
                                <div className="faq__scroll">
                                    <CustomScroll
                                        heightRelativeToParent="100%"
                                        allowOuterScroll={true}
                                    >
                                        <div className={'faq__list' + (faqFull ? ' faq__list--full' : '')}>
                                            {faq.map((fq, i) => (
                                                <div onClick={()=>{
                                                    if(faqCur === (i + 1)) {
                                                        setFaqCur(-1);
                                                    }else {
                                                        setFaqCur(i + 1);
                                                    }

                                                }} className={'faq__it fadeIn animated' + (faqCur == (i + 1) ? ' active' : '') + (i > 4 ? ' faq__it--hidden' : '')}>
                                                    <div className="faq__it-hd">
                                                        <div className="faq__it-title">
                                                            {parse(fq.title)}
                                                        </div>
                                                        <div className="faq__it-icon">
                                                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14 2L8 8L2 2" stroke="black" strokeWidth="3" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="faq__it-answer fadeIn animated">
                                                        <div className="faq__it-txt">
                                                            {parse(fq.answer)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CustomScroll>
                                </div>
                            </div>
                            <div className="faq__right">
                                <div className="feedback__descr">
                                    Не&nbsp;нашли ответа на&nbsp;свой вопрос? Напишите нам!
                                </div>
                                <div className="feedback__action">
                                    <div onClick={()=>{
                                        show(<Feedback/>, "modal--form modal--feedback");
                                        if(window.ym){
                                            window.ym(97392085,'reachGoal','кнопка Задать вопрос');
                                        }
                                    }} className="btn">
                                        <span>Задать вопрос</span>
                                        <i className="btn__arrow">
                                            <svg>
                                                <use href="#btn-arrow"></use>
                                            </svg>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="action__fixed" id="actions-codes">
                <div className="action__fixed-title">
                    Зарегистрировать чек
                </div>
                <CodesWidget classesList="action__fixed-btn" module="main"/>
                <a onClick={()=>{
                    if(window.ym){
                        window.ym(97392085,'reachGoal','кнопка Перехода в мессенджер');
                    }
                }} href={'https://t.me/delicados_promo_bot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.promocode : '?start=NEW')} target="_blank" className="promo__btn-tg">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.8121 0.40884C8.9921 0.347812 0.171475 9.0695 0.110447 19.8895C0.049419 30.7094 8.7711 39.53 19.5911 39.5911C30.411 39.6521 39.2316 30.9304 39.2927 20.1105C39.3537 9.2905 30.632 0.469868 19.8121 0.40884Z" fill="#40B3E0"/>
                        <path d="M29.2803 11.7343L25.6816 29.3568C25.6816 29.3568 25.1854 30.5776 23.8429 29.9827L15.8029 23.7464L12.8747 22.3107L7.94131 20.6188C7.94131 20.6188 7.18428 20.3455 7.11413 19.7578C7.04413 19.1701 7.97573 18.8573 7.97573 18.8573L27.6679 11.2602C27.6679 11.2602 29.2869 10.5598 29.2803 11.7343Z" fill="white"/>
                        <path d="M15.1541 29.0965C15.1541 29.0965 14.9185 29.0731 14.63 28.1415C14.3417 27.21 12.8767 22.3078 12.8767 22.3078L24.7867 14.8383C24.7867 14.8383 25.4743 14.4261 25.4475 14.842C25.4475 14.842 25.5693 14.916 25.2004 15.2565C24.8314 15.5971 15.8303 23.5968 15.8303 23.5968" fill="#D2E5F1"/>
                        <path d="M18.8873 26.1344L15.6769 29.0284C15.6769 29.0284 15.4262 29.2165 15.1537 29.0961L15.7958 23.6905" fill="#B5CFE4"/>
                    </svg>

                </a>
            </div>
        </>
    );
}

export default Home;
