import parse from 'html-react-parser';
import ReactDraggable from 'react-draggable';
import {gsap, Power2} from "gsap";
import React, {useEffect, useRef, useState} from "react";
import axiosInstance from "../../helpers/axios";
import useEffectOnce from "../../hooks/useEffectOnce";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import {Link} from "react-router-dom";
import {useModal, useProfile} from "../../context";

let enableDrag = true;
let enableDrag2 = true;

let gameQuestions = [
    {
        id: 1,
        title: 'Смахни вправо форму Delicados Nachos, <br/>влево — обычные чипсы',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/1-left.png'),
        imgRight: require('../../assets/img/game/1-right.png')
    },
    {
        id: 2,
        title: 'Смахни вправо год основания бренда Delicados, влево — год появления чипсов в&nbsp;России',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/2-left.png'),
        imgRight: require('../../assets/img/game/2-right.png')
    },
    {
        id: 3,
        title: 'Какой самый популярный вкус начос в&nbsp;линейке Delicados? Смахни его вправо, а&nbsp;самый популярный вкус обычных чипсов&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/3-left.png'),
        imgRight: require('../../assets/img/game/3-right.png')
    },
    {
        id: 4,
        title: 'Перед тобой два популярных блюда, но&nbsp;только одно из&nbsp;них делают из&nbsp;кукурузных чипсов. Смахни его вправо, другое&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/4-left.png'),
        imgRight: require('../../assets/img/game/4-right.png')
    },
    {
        id: 5,
        title: 'Знаешь&nbsp;ли ты&nbsp;о&nbsp;таких праздниках? Смахни вправо дату Международного дня начос, влево&nbsp;&mdash; дату Дня картофельных чипсов',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/5-left.png'),
        imgRight: require('../../assets/img/game/5-right.png')
    },
    {
        id: 6,
        title: 'В&nbsp;каком году запущен уникальный вкус Delicados с&nbsp;кусочками оливок и&nbsp;паприкой? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/6-left.png'),
        imgRight: require('../../assets/img/game/6-right.png')
    },
    {
        id: 7,
        title: 'Если тебя разбудить среди ночи и&nbsp;спросить, сколько весит упаковка Delicados, что ты&nbsp;ответишь? Правильный ответ смахни вправо, а&nbsp;неправильный&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/7-left.png'),
        imgRight: require('../../assets/img/game/7-right.png')
    },
    {
        id: 8,
        title: 'Упаковка Delicados&nbsp;&mdash; это не&nbsp;только вкусно, но&nbsp;и&nbsp;красиво. А&nbsp;в&nbsp;каком году были созданы пачки с&nbsp;этим дизайном? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/8-left.png'),
        imgRight: require('../../assets/img/game/8-right.png')
    },
    {
        id: 9,
        title: 'Бренд Delicados представлен в&nbsp;разных категориях товаров. А&nbsp;ты&nbsp;знаешь, в&nbsp;каких именно? Правильный ответ смахни вправо, неправильный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/9-left.png'),
        imgRight: require('../../assets/img/game/9-right.png')
    },
    {
        id: 10,
        title: 'Delicados предлагает тебе целую линейку разных вкусов и&nbsp;постоянно придумывает что-то новое. Сколько всего вкусов у&nbsp;Delicados сегодня? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/10-left.png'),
        imgRight: require('../../assets/img/game/10-right.png')
    },
    {
        id: 11,
        title: 'Какой вкус начос больше всего подходит для гуакамоле? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/11-left.png'),
        imgRight: require('../../assets/img/game/11-right.png')
    },
    {
        id: 12,
        title: 'Перед тобой два уникальных вкуса, но&nbsp;лишь один из&nbsp;них представлен в&nbsp;линейке Delicados. Какой? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/12-left.png'),
        imgRight: require('../../assets/img/game/12-right.png')
    },
    {
        id: 13,
        title: 'Перед тобой два соуса, но&nbsp;только один из&nbsp;них ты&nbsp;можешь увидеть на&nbsp;упаковке Delicados и&nbsp;почувствовать его великолепный вкус. Что это за&nbsp;соус? Правильный ответ смахни вправо, неверный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/13-left.png'),
        imgRight: require('../../assets/img/game/13-right.png')
    },
    {
        id: 14,
        title: 'Хмм... И&nbsp;то, и&nbsp;то&nbsp;&mdash; кукуруза. Но&nbsp;из&nbsp;чего делают Delicados, из&nbsp;муки или из&nbsp;цельного зерна? Правильный ответ смахни вправо, неправильный&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/14-left.png'),
        imgRight: require('../../assets/img/game/14-right.png')
    },
    {
        id: 15,
        title: 'На&nbsp;каком масле обжариваются начос? Правильный ответ вправо, неправильный&nbsp;&mdash; влево!',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/15-left.png'),
        imgRight: require('../../assets/img/game/15-right.png')
    },
    {
        id: 16,
        title: 'Натуральные кусочки каких овощей и&nbsp;приправ добавляются в&nbsp;новую линейку Delicados Nachos? Правильный ответ смахивай вправо, неправильный&nbsp;&mdash; влево!',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/16-left.png'),
        imgRight: require('../../assets/img/game/16-right.png')
    },
    {
        id: 17,
        title: 'Где выращивают основной продукт для производства Delicados Nachos? Правильный ответ смахни вправо, а&nbsp;неверный&nbsp;&mdash; налево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/17-left.png'),
        imgRight: require('../../assets/img/game/17-right.png')
    },
    {
        id: 18,
        title: 'Как всё вкусно! Но&nbsp;только на&nbsp;одной из&nbsp;картинок мы&nbsp;видим Delicados Nachos. Смахивай её&nbsp;вправо, а&nbsp;другую&nbsp;&mdash; налево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/18-left.png'),
        imgRight: require('../../assets/img/game/18-right.png')
    },
    {
        id: 19,
        title: 'Один из&nbsp;этих узоров на&nbsp;родине Delicados Nachos встречается чаще, чем в&nbsp;России. Выбери его и&nbsp;смахни вправо, а&nbsp;неправильный ответ&nbsp;&mdash; налево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/19-left.png'),
        imgRight: require('../../assets/img/game/19-right.png')
    },
    {
        id: 20,
        title: 'Только один из&nbsp;этих головных уборов популярен на&nbsp;родине Delicados Nachos. Какой? Смахни его вправо, а&nbsp;другой&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/20-left.png'),
        imgRight: require('../../assets/img/game/20-right.png')
    },
    {
        id: 21,
        title: 'Где чаще подают начос с&nbsp;соусами? Смахни правильный вариант вправо, а&nbsp;неверный влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/21-left.png'),
        imgRight: require('../../assets/img/game/21-right.png')
    },
    {
        id: 22,
        title: 'Один из&nbsp;этих сыров отлично сочетается с&nbsp;Delicados Nachos. А&nbsp;может, оба? Смахни свой ответ вправо, а&nbsp;другой сыр&nbsp;&mdash; налево',
        correct: {
            left: 'left-right',
            right: 'left-right'
        },
        imgLeft: require('../../assets/img/game/22-left.png'),
        imgRight: require('../../assets/img/game/22-right.png')
    },
    {
        id: 23,
        title: 'Какая страна является родиной Delicados Nachos? Правильный ответ смахни вправо',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/23-left.png'),
        imgRight: require('../../assets/img/game/23-right.png')
    },
    {
        id: 24,
        title: 'Какой из&nbsp;этих вкусов был представлен первым в&nbsp;линейке Delicados? Смахни правильный ответ вправо, а&nbsp;следующий по&nbsp;хронологии вкус&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/24-left.png'),
        imgRight: require('../../assets/img/game/24-right.png')
    },
    {
        id: 25,
        title: 'Где ты&nbsp;бы предпочел наслаждаться Delicados Nachos? Правильный ответ смахни вправо!',
        correct: {
            left: 'left-right',
            right: 'left-right'
        },
        imgLeft: require('../../assets/img/game/25-left.png'),
        imgRight: require('../../assets/img/game/25-right.png')
    },
    {
        id: 26,
        title: 'Какой вкус более острый? Смахни правильный ответ вправо, а&nbsp;более мягкий вкус смахни влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/26-left.png'),
        imgRight: require('../../assets/img/game/26-right.png')
    },
    {
        id: 27,
        title: 'Какой из&nbsp;этих двух дизайнов пачки был создан первым? Смахни его вправо, а&nbsp;более современный вариант&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/27-left.png'),
        imgRight: require('../../assets/img/game/27-right.png')
    },
    {
        id: 28,
        title: 'Какой музыкальный инструмент поможет создать идеальное настроение для вечеринки с&nbsp;Delicados? Смахни правильный ответ вправо, в&nbsp;другой налево, но&nbsp;помни, что всё зависит только от&nbsp;твоего вкуса!',
        correct: {
            left: 'left-right',
            right: 'left-right'
        },
        imgLeft: require('../../assets/img/game/28-left.png'),
        imgRight: require('../../assets/img/game/28-right.png')
    },
    {
        id: 29,
        title: 'Что проще использовать на&nbsp;кухне для приготовления мексиканских блюд? Смахни правильный ответ вправо, а&nbsp;устаревший&nbsp;&mdash; влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/29-left.png'),
        imgRight: require('../../assets/img/game/29-right.png')
    },
    {
        id: 30,
        title: 'Для какого мероприятия Delicados станут идеальной закуской? Смахни правильный ответ вправо, а&nbsp;другой&nbsp;&mdash; налево!',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/30-left.png'),
        imgRight: require('../../assets/img/game/30-right.png')
    },
    {
        id: 31,
        title: 'Смахни вправо&nbsp;то, что станет лучшим аксессуаром для фанатов во&nbsp;время просмотра футбольного матча. А&nbsp;то, что больше пригодится на&nbsp;поле, смахни влево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/31-left.png'),
        imgRight: require('../../assets/img/game/31-right.png')
    },
    {
        id: 32,
        title: 'Какой из&nbsp;этих ингредиентов лучше сочетается с&nbsp;Delicados Nachos? Смахни правильный ответ вправо, неправильный&nbsp;&mdash; влево',
        correct: {
            left: 'left',
            right: 'right'
        },
        imgLeft: require('../../assets/img/game/32-left.png'),
        imgRight: require('../../assets/img/game/32-right.png')
    },
    {
        id: 33,
        title: 'Какая страна является родиной Delicados? Смахни правильный ответ вправо',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/33-left.png'),
        imgRight: require('../../assets/img/game/33-right.png')
    },
    {
        id: 34,
        title: 'Только один из&nbsp;этих вкусов есть в&nbsp;линейке Delicados. Какой именно? Смахни свой ответ вправо, а&nbsp;неподходящее ракообразное пусть идёт налево',
        correct: {
            left: 'right',
            right: 'left'
        },
        imgLeft: require('../../assets/img/game/34-left.png'),
        imgRight: require('../../assets/img/game/34-right.png')
    },
]

function Game(){
    const decorateOptions = (defaultOptions) => {
        return {
            ...defaultOptions,
            colors: ["#FFFFFF", "#C0C0C0"],
            shapes: ['square'],
            ticks: 300
        };
    };

    const confettiRef = useRef();

    const {hide} = useModal();
    const {profile, setProfile} = useProfile();

    const [dragDir, setDragDir] = useState(null);
    const [controlledPosition, setControlledPosition] = useState({x: 0, y: 0});
    const [controlledPosition2, setControlledPosition2] = useState({x: 0, y: 0});
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState({
        'left': null,
        'right': null
    });
    const [loadingAnswers, setLoadingAnswers] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentAnswerMark, setCurrentAnswerMark] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [result, setResult] = useState(false);
    const [limit, setLimit] = useState(false);

    /*useEffectOnce(()=>{
        getUserGameAnswers();
    });*/

    useEffect(()=>{
        if(!profile.isLoading && profile.loggedIn){
            if(profile.data.current_question_number === profile.data.last_question_number){
                setLimit(true);
            }else{
                setCurrentQuestion(profile.data.last_question_number + 1);
            }
            setLoadingAnswers(false);
        }
        console.log(profile);
    }, [profile]);

    useEffect(()=>{
        if(currentQuestion){
            setResult(false);
            setCurrentAnswer({
                left: null,
                right: null
            });
            setControlledPosition({x: 0, y: 0});
            setControlledPosition2({x: 0, y: 0});
            document.querySelector('.modal__content-box').classList.remove('animated', 'shake');
            setCurrentAnswerMark(false);
        }

    }, [currentQuestion]);

    useEffect(()=>{
        if(!result && currentQuestion){
            gsap.to(document.querySelector('.game__modal-img--left'), {
                x: 0,
                opacity: 1,
                duration: 0.4,
                delay: 0.7,
                onComplete: ()=> {
                    enableDrag = true;
                }
            });
            gsap.to(document.querySelector('.game__modal-img--right'), {
                x: 0,
                opacity: 1,
                duration: 0.4,
                delay: 0.7,
                onComplete: ()=> {
                    enableDrag2 = true;
                }
            });
        }
    }, [result, currentQuestion]);

    useEffect(()=>{
        if(currentAnswer.left && currentAnswer.right){
            sendAnswer();
        }
    }, [currentAnswer]);

    const handleDrag = (e, ui) => {
        if(ui.x > 0) {
            if(Math.abs(ui.x) >= 120){
                if(enableDrag){
                    enableDrag = false;
                    setControlledPosition({x: 120, y: 0});
                    gsap.to('.game__modal-img--left', {
                        x: '20rem',
                        scale: 0.5,
                        opacity: 0,
                        duration: 0.3,
                        onComplete: ()=> {
                            setCurrentAnswer({
                                ...currentAnswer,
                                'left': 'right'
                            });
                        }
                    });
                }
            }
        }else{
            if(Math.abs(ui.x) >= 120){
                if(enableDrag){
                    enableDrag = false;
                    setControlledPosition({x: -120, y: 0});
                    gsap.to('.game__modal-img--left', {
                        x: '-20rem',
                        scale: 0.5,
                        opacity: 0,
                        duration: 0.3,
                        onComplete: ()=> {
                            setCurrentAnswer({
                                ...currentAnswer,
                                'left': 'left'
                            });
                        }
                    });
                }
            }
        }
    }

    const handleDrag2 = (e, ui) => {
        if(ui.x > 0) {
            if(Math.abs(ui.x) >= 120){
                if(enableDrag2){
                    enableDrag2 = false;
                    setControlledPosition2({x: 120, y: 0});
                    gsap.to('.game__modal-img--right', {
                        x: '20rem',
                        scale: 0.5,
                        opacity: 0,
                        onComplete: ()=> {
                            setCurrentAnswer({
                                ...currentAnswer,
                                'right': 'right'
                            });
                        }
                    });
                }
            }
        }else{
            if(Math.abs(ui.x) >= 120){
                if(enableDrag2){
                    enableDrag2 = false;
                    setControlledPosition2({x: -120, y: 0});
                    gsap.to('.game__modal-img--right', {
                        x: '-20rem',
                        scale: 0.5,
                        opacity: 0,
                        onComplete: ()=> {
                            setCurrentAnswer({
                                ...currentAnswer,
                                'right': 'left'
                            });
                        }
                    });
                }
            }
        }
    }

    const handleDragStop = (e, ui) => {
        if(Math.abs(ui.x) < 50){
            setControlledPosition({x: 0, y: 0});
        }
    };

    const handleDragStop2 = (e, ui) => {
        if(Math.abs(ui.x) < 50){
            setControlledPosition2({x: 0, y: 0});
        }
    };

    const onInit = ({ conductor }) => {
        confettiRef.current = conductor;
        setTimeout(function (){
            confettiRef.current.shoot();
        }, 400);
    };

    /*const getUserGameAnswers = async() => {
        try {
            const response = await axiosInstance.get('/game/api/get-user-answers');
            if(response.data){
                setAnswers(response.data.items);
                if(enableQuestion === response.data.items.length){
                    setLimit(true);
                }else{
                    setCurrentQuestion(enableQuestion - (enableQuestion - response.data.items.length) + 1);
                }
                setLoadingAnswers(false);
            }
        } catch (error) {
            if(error.response.data){
                setLoadingAnswers(false);
            }
        }
    }*/

    const sendAnswer = async() => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("answer_one", currentAnswer.left);
        formData.append("answer_two", currentAnswer.right);

        let correctOne = gameQuestions[currentQuestion - 1].correct.left;
        let correctTwo = gameQuestions[currentQuestion - 1].correct.right;
        if([22, 25, 28].includes(currentQuestion)){
            correctOne = 'right';
            correctTwo = 'left';
        }

        formData.append("correct_answer_one", correctOne);
        formData.append("correct_answer_two", correctTwo);
        formData.append("hash", 1);
        formData.append("question_number", currentQuestion);

        try {
            const response = await axiosInstance.post('/game/api/answer', formData);
            if(response.data){
                let resMark = (currentAnswer.left === gameQuestions[currentQuestion - 1].correct.left) && (currentAnswer.right === gameQuestions[currentQuestion - 1].correct.right);

                if([22, 25, 28].includes(currentQuestion)){
                    resMark = true;
                }

                setCurrentAnswerMark(resMark);
                setResult(true);

                if(!resMark){
                    document.querySelector('.modal__content-box').classList.add('animated', 'shake');
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <div className="game__decor-1"/>
            <div className="game__decor-2"/>
            <div className="game__decor-3"/>
            <div className="game__decor-4"/>
            <div className="game__decor-5"/>
            <div className="game__decor-6"/>
            {(loadingAnswers || (!currentQuestion && !limit) || isSubmitting) && (
                <div className="loader--inline fadeIn animated">
                    <img src={require("../../assets/img/loader-wh.svg").default}/>
                </div>
            )}
            {!loadingAnswers && !isSubmitting && (currentQuestion || limit) && (
                <>
                    {!result && currentQuestion && (
                        <>
                            <div className="game__modal-header fadeIn animated">
                                <div className="game__modal-title">
                                    {parse(gameQuestions[currentQuestion - 1].title)}
                                </div>
                            </div>
                            <ReactDraggable
                                axis="x"
                                onDrag={handleDrag}
                                position={controlledPosition}
                                bounds={{top: 0, left: -120, right: 120, bottom: 0}}
                                onStop={handleDragStop}>
                                <div className="game__modal-it game__modal-it--left">
                                    <div className="game__modal-img game__modal-img--left">
                                        <img src={gameQuestions[currentQuestion - 1].imgLeft}/>
                                    </div>
                                </div>
                            </ReactDraggable>
                            <ReactDraggable
                                axis="x"
                                onDrag={handleDrag2}
                                position={controlledPosition2}
                                bounds={{top: 0, left: -120, right: 120, bottom: 0}}
                                onStop={handleDragStop2}>
                                <div className="game__modal-it game__modal-it--right">
                                    <div className="game__modal-img game__modal-img--right">
                                        <img src={gameQuestions[currentQuestion - 1].imgRight}/>
                                    </div>
                                </div>
                            </ReactDraggable>
                        </>
                    )}
                    {result && (
                        <div className="game__result-box fadeIn animated">
                            {currentAnswerMark && (
                                <div className="game__result-animation">
                                    <Fireworks
                                        onInit={onInit}
                                        decorateOptions={decorateOptions}/>
                                </div>
                            )}
                            <div className="game__result-ct">
                                {currentAnswerMark && (
                                    <>
                                        {currentQuestion === gameQuestions.length && (
                                            <>
                                                <div className="game__result-img">
                                                    <img src={require("../../assets/img/game-passed-img.png")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="game__result-title">
                                                    Поздравляем!
                                                </div>
                                            </>
                                        )}
                                        {currentQuestion < gameQuestions.length && (
                                            <div className="game__result-title">
                                                Поздравляем! <br/>
                                                Это правильный ответ
                                            </div>
                                        )}

                                        {currentQuestion < gameQuestions.length && (
                                            <>
                                                {currentQuestion < profile.data.current_question_number && (
                                                    <div className="game__result-subtitle">
                                                        Перейдите на следующий вопрос
                                                    </div>
                                                )}

                                                {currentQuestion === profile.data.current_question_number && (
                                                    <div className="game__result-subtitle">
                                                        Возвращайтесь за новым вопросом завтра!
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {currentQuestion === gameQuestions.length && (
                                            <div className="game__result-subtitle">
                                                Вы&nbsp;прошли всю игру, ожидайте <br/>
                                                розыгрыша Специального приза!
                                            </div>
                                        )}

                                        <div className="game__result-action">
                                            {currentQuestion < profile.data.current_question_number && (
                                                <div onClick={()=>{
                                                    setProfile({
                                                        ...profile,
                                                        data: {
                                                            ...profile.data,
                                                            last_question_number: currentQuestion
                                                        }
                                                    });
                                                }} className="btn">
                                                    <span>Дальше</span>
                                                    <i className="btn__arrow">
                                                        <svg>
                                                            <use href="#btn-arrow"></use>
                                                        </svg>
                                                    </i>
                                                </div>
                                            )}
                                            {(currentQuestion === profile.data.current_question_number || currentQuestion === gameQuestions.length) && (
                                                <div onClick={()=>{
                                                    setProfile({
                                                        ...profile,
                                                        data: {
                                                            ...profile.data,
                                                            last_question_number: profile.data.current_question_number
                                                        }
                                                    });
                                                    hide();
                                                }} className="btn">
                                                    <span>На главную</span>
                                                    <i className="btn__arrow">
                                                        <svg>
                                                            <use href="#btn-arrow"></use>
                                                        </svg>
                                                    </i>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                {!currentAnswerMark && (
                                    <>
                                        {currentQuestion < gameQuestions.length && (
                                            <>
                                                <div className="game__result-title">
                                                    Упс, кажется Вы <br/>
                                                    ошиблись!
                                                </div>
                                                {currentQuestion < profile.data.current_question_number && (
                                                    <div className="game__result-subtitle">
                                                        Перейдите на следующий вопрос
                                                    </div>
                                                )}

                                                {currentQuestion === profile.data.current_question_number && (
                                                    <div className="game__result-subtitle">
                                                        Не&nbsp;расстраивайтесь и&nbsp;возвращайтесь <br/>
                                                        завтра за&nbsp;новым вопросом!
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {currentQuestion === gameQuestions.length && (
                                            <>
                                                <div className="game__result-title">
                                                    Не расстраивайтесь!
                                                </div>
                                                <div className="game__result-subtitle">
                                                    К&nbsp;сожалению все попытки игры <br/>
                                                    исчерпаны! Участвуйте в&nbsp;розыгрыше <br/>
                                                    других призов от&nbsp;Delicados!
                                                </div>
                                            </>
                                        )}

                                        <div className="game__result-action">
                                            {currentQuestion < profile.data.current_question_number && (
                                                <div onClick={()=>{
                                                    setProfile({
                                                        ...profile,
                                                        data: {
                                                            ...profile.data,
                                                            last_question_number: currentQuestion
                                                        }
                                                    });
                                                }} className="btn">
                                                    <span>Дальше</span>
                                                    <i className="btn__arrow">
                                                        <svg>
                                                            <use href="#btn-arrow"></use>
                                                        </svg>
                                                    </i>
                                                </div>
                                            )}
                                            {(currentQuestion === profile.data.current_question_number || currentQuestion === gameQuestions.length) && (
                                                <div onClick={()=>{
                                                    setProfile({
                                                        ...profile,
                                                        data: {
                                                            ...profile.data,
                                                            last_question_number: current_question_number
                                                        }
                                                    });
                                                    hide();
                                                }} className="btn">
                                                    <span>На главную</span>
                                                    <i className="btn__arrow">
                                                        <svg>
                                                            <use href="#btn-arrow"></use>
                                                        </svg>
                                                    </i>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {limit && (
                        <div className="game__result-box fadeIn animated">
                            <div className="game__result-ct">
                                <>
                                    <div className="game__result-title">
                                        Мы уже получили ваш ответ
                                    </div>
                                    <div className="game__result-subtitle">
                                        Возвращайтесь за новым вопросом завтра!
                                    </div>

                                    <div className="game__result-action">
                                        <div onClick={()=>{
                                            hide();
                                            setProfile({
                                                ...profile,
                                                data: {
                                                    ...profile.data,
                                                    last_question_number: profile.data.current_question_number
                                                }
                                            });
                                        }} className="btn">
                                            <span>На главную</span>
                                            <i className="btn__arrow">
                                                <svg>
                                                    <use href="#btn-arrow"></use>
                                                </svg>
                                            </i>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default Game;
